
import React from 'react';

import {HubSpot} from './_HubSpot';
import {Smartlook} from './_Smartlook';
import {TikTok} from './_TikTok';

export const Scripts = () => {
	return (
		<>
			<HubSpot />
			<Smartlook />
			<TikTok />
		</>
	);
};
