import React from 'react';
import {useEffect} from 'react';
import {Script} from 'gatsby';

const lookId = process.env.GATSBY_SMARTLOOK_ID;
export const lookScript = !lookId ? '' : `
if (typeof window !== "undefined") {
	window.smartlook || (function(d) {
		var o = smartlook = function() {
				o.api.push(arguments)
			},
			h = d.getElementsByTagName('head')[0];
		var c = d.createElement('script');
		o.api = new Array();
		c.async = true;
		c.type = 'text/javascript';
		c.charset = 'utf-8';
		c.src = 'https://web-sdk.smartlook.com/recorder.js';
		h.appendChild(c);
	})(document);
	smartlook('init', '${lookId}', {
		region: 'eu'
	});
}`;

const isSkippableError = (e: ErrorEvent): boolean => {
	const t = e.target;

	// Verify message is NOT set and that we're an element
	if (!!e.message || !t) {
		return false;
	} else if (!(t instanceof HTMLElement)) {
		return false;
	}

	// Crawl up DOM to see if we're inside <head /> or <iframe />
	if (isChildOf(t, (p) => (
		p instanceof HTMLHeadElement ||
		p instanceof HTMLIFrameElement
	))) {
		return true;
	}

	// Check for failed load on blob/data URI
	{
		const src = t.getAttribute('src');
		if (src && (src.startsWith('blob://') || src.startsWith('data:'))) {
			return true;
		}
	}

	return false;
};

const isChildOf = (e: HTMLElement, check: (p: HTMLElement) => boolean): boolean => {
	for (let cursor = e; ;) {
		if (check(cursor)) {
			return true;
		} else if (cursor.parentElement === null) {
			return false;
		}
		cursor = cursor.parentElement;
	}
};

export const Smartlook = () => {
	useEffect(() => {
		if (lookScript) {
			window.addEventListener('error', (e) => {
				if (isSkippableError(e)) {
					e.preventDefault();
					e.stopImmediatePropagation();
				}
			}, true);
		}
	}, []);

	return <>{lookScript && <Script>{lookScript}</Script>}</>;
};
