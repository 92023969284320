import Cookies, {CookieAttributes} from 'js-cookie';

export enum CookKey {
	Appointment = 'appt',
	Checkout = 'checkout',
	CookieBanner = 'cookie-banner',
	LeadCapture = 'lead-capture',
	LocalId = 'local-id',
	Product = 'product',
	Referrer = 'referrer',
	Region = 'region',
	Token = 'token',
}

export const cookies = {
	get(k: CookKey): string | undefined {
		return Cookies.get(k);
	},

	set(k: CookKey, v: string, attr?: CookieAttributes) {
		Cookies.set(k, v, attr);
	},

	remove(k: CookKey, attr?: CookieAttributes) {
		Cookies.remove(k, attr);
	},
};
