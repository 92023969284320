
import {navigate} from 'gatsby';

import {cookies, CookKey} from './cookies';
import {hasWindow} from './functions';

export const getToken = () => cookies.get(CookKey.Token);

export const setToken = (token: string) => {
	// Let the API determine expiration window.
	// This is much shorter than the # of days
	// that is encoded here.
	cookies.set(CookKey.Token, token, {expires: 90});
};

export const clearToken = () => {
	cookies.remove(CookKey.Token);
};

export const createRedirect = (base: string, target?: string) => {
	if (!target) {
		if (!hasWindow()) {
			return base;
		}

		// If url = https://xyz/123?ab=1#h
		// Remove : https://xyz
		const origin = window.location.origin.length;
		target = window.location.href.substring(origin) || '';
	}

	// Replace any leading slashes
	if (target.charAt(0) === '/') {
		target = target.replace(/^\/+/, '');
	}

	return `${base}?redirect_url=${encodeURIComponent(target)}`;
};

export const applyRedirect = (fallback: string) => {
	const redirect = new URLSearchParams(location.search).get('redirect_url');
	if (!redirect) {
		navigate(fallback);
		return;
	}

	const decoded = decodeURIComponent(redirect);
	navigate(`/${decoded}`);
};
