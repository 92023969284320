
import {hasWindow} from 'utils/functions';

// Define global object on window
declare global {
	interface Window {
		smartlook?(
			action: string,
			value?: string,
			detail?: Record<string, unknown>,
		): void;
	}
}

let lastId = '';

export const smartlook = {
	identify: (id: string) => {
		if (!id || id === lastId) {
			return;
		}

		if (hasWindow() && window.smartlook) {
			window.smartlook('identify', id);
			lastId = id;
		}
	},

	track: (event: SLEvent, details?: Record<string, unknown>) => {
		if (hasWindow() && window.smartlook) {
			window.smartlook('track', event, details);
		}
	},
};

export enum SLEvent {
	ChatStarted = 'chat-started',
	UserPurchase = 'user-purchase',
	UserSubscribe = 'user-subscribe',
	UserSignUp = 'user-signup',
}
